






































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop } from 'vue-property-decorator'
import { mdiArrowUp } from '@mdi/js'
import { mapState } from 'vuex'
import TableFilterModal from '@/components/multiple-products-editor/TableFilterModal.vue'
import TableContextMenu from '@/components/multiple-products-editor/TableContextMenu.vue'
import SegmentOverview from '@/components/multiple-products-editor/SegmentOverview.vue'

@Component({
    components: {
        TableContextMenu,
        TableFilterModal,
        SegmentOverview
    },
    computed: {
        ...mapState('mpe', ['appliedSortOptions'])
    }
})
class TableHeader extends Vue {
    @Prop({ type: Array as () => TableCriteriaItem[], required: true })
    readonly criteria!: TableCriteriaItem[]

    @Prop({ type: Boolean, default: true })
    readonly hasCheckColumn!: boolean

    // mapState
    readonly appliedSortOptions!: SortOptions

    // data
    readonly arrowIcon = mdiArrowUp
    private selectedItemColumn = ''
    private showMenu = false
    private x = 0
    private y = 0
    private sortBy: string | null = 'id'
    private sortDesc: boolean = true

    private dragOrigin: number | null = null
    private dragTarget: number | null = null

    private isResizeDrag = false
    private resizeStartX = 0
    private resizeHeader: HTMLElement | null = null
    private resizeHeaderSuc: HTMLElement | null = null

    private tableWrapper: HTMLElement | null = null
    private tableContent: HTMLElement | null = null

    public created() {
        document.addEventListener('mouseup', this.resizeEnd)
        document.addEventListener('mousemove', this.resizeMove)
        this.tableWrapper = document.querySelector('.v-data-table__wrapper') as HTMLElement
        this.tableContent = document.querySelector('.v-data-table__wrapper table') as HTMLElement
    }

    private resizeStart(e: MouseEvent, i: number) {
        this.isResizeDrag = true
        this.resizeHeader = document.querySelector(`.header_${i}`)
        this.resizeHeaderSuc = document.querySelector(`.header_${i + 1}`)
        this.resizeStartX = e.pageX
    }
    private resizeEnd() {
        if (this.isResizeDrag) {
            this.isResizeDrag = false
            this.resizeHeader = null
        }
    }

    private resizeMove(e: MouseEvent) {
        if (this.isResizeDrag && this.resizeHeader) {
            const diff = this.resizeStartX - e.pageX
            const rightBoorderCoordinate = this.resizeHeader.getBoundingClientRect().x + this.resizeHeader.offsetWidth
            if (diff > 0 || (diff < 0 && e.x > rightBoorderCoordinate)) {
                const newWidth = this.resizeHeader.offsetWidth - diff
                const tableWidthVal = this.tableContent ? this.tableContent.offsetWidth : 0
                const wrapperWidthVal = this.tableWrapper ? this.tableWrapper.offsetWidth - 15 : 0
                const hasScrollbar = tableWidthVal - wrapperWidthVal > 0

                if (newWidth >= 100) {
                    if (this.resizeHeaderSuc && !hasScrollbar && diff > 0) {
                        const newWidthSuc = this.resizeHeaderSuc.offsetWidth + diff
                        if (newWidthSuc >= 100) {
                            this.resizeHeaderSuc.style.minWidth = `${newWidthSuc}px`
                            this.resizeHeaderSuc.style.maxWidth = `${newWidthSuc}px`
                        }
                    }
                    this.resizeHeader.style.minWidth = `${newWidth}px`
                    this.resizeHeader.style.maxWidth = `${newWidth}px`
                }
            }
            this.resizeStartX = e.pageX
        }
    }

    private onHeaderClick(val: TableCriteriaItem) {
        this.$store.dispatch('mpe/applySortOptions', val.id)
    }

    private onHeaderCloseClick(val: TableCriteriaItem) {
        this.$store.dispatch('mpe/applyFilter', {
            key: val.id,
            values: []
        })
        this.$store.dispatch('mpe/removeSelectedCriteriaWithId', val.id)
    }

    private onValueSelected(value: string | null) {
        this.$emit('applyValueToColumn', this.selectedItemColumn, value)
    }

    private show(e: MouseEvent, clickedCriteriaHeader: TableCriteriaItem) {
        e.preventDefault()
        if (clickedCriteriaHeader.isInEditScope && clickedCriteriaHeader.isEditable) {
            this.selectedItemColumn = clickedCriteriaHeader.id
            this.closeMenu()
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        }
    }

    private closeMenu() {
        this.showMenu = false
    }

    private dragStart(e: DragEvent, startIndex: number) {
        if (this.isResizeDrag) {
            e.preventDefault()
        }
        if (this.dragOrigin !== startIndex) {
            this.dragOrigin = startIndex
        }
    }

    private dragEnter(endIndex: number) {
        if (this.dragTarget !== endIndex) {
            this.dragTarget = endIndex
        }
    }

    private dragOver(e: DragEvent) {
        if (this.dragTarget === -1) {
            e.preventDefault()
        }
    }

    private drop() {
        if (this.dragOrigin !== null && this.dragTarget !== null && this.dragOrigin !== this.dragTarget) {
            this.$store.dispatch('mpe/reorderCriteria', {
                from: this.dragOrigin,
                to: this.dragTarget
            })
            this.$forceUpdate()
        }
    }

    private dragEnd() {
        this.dragOrigin = null
        this.dragTarget = null
    }
}

export default TableHeader
