




































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import validationRules from './TableInputValidation'

const bannedFloatInput = 'e+-.'
const bannedIntInput = ','.concat(bannedFloatInput)
const floatTables = ['dosageLevels']

@Component({
    computed: {
        ...mapState('mpe', ['originProducts'])
    }
})
class TableInput extends Vue {
    @Prop({ type: Object as () => Product, required: true })
    readonly product!: Product
    @Prop({ type: String, required: true })
    readonly path!: string
    @Prop({ type: String, default: 'text' })
    readonly type!: string
    @Prop({ type: Boolean, default: false })
    readonly isDisabled!: boolean

    // MapState
    readonly originProducts!: KeyMap<Product>

    // fields
    private openTextArea: boolean = false

    get inputTextArea() {
        const comp = this.$refs.inputTextArea as Vue
        return comp.$el as HTMLTextAreaElement
    }

    get value() {
        if (this.type === 'number' && this.product[this.path] === 'UNSET') {
            return null
        }
        return this.product[this.path]
    }

    set value(val) {
        this.$store.commit('mpe/setProductValue', {
            id: this.product.id,
            path: this.path,
            value:
                this.type === 'number' && val !== 'UNSET'
                    ? val.toString().length > 0
                        ? parseFloat(val)
                        : 'UNSET'
                    : val
        })
        this.$store.dispatch('mpe/updateScoreForProduct', { id: this.product.id })
    }

    get isValid() {
        if (validationRules[this.path]) {
            return validationRules[this.path](this.value)
        }
        return true
    }

    private onFocus() {
        if (!this.openTextArea && this.type === 'text') {
            this.openTextArea = true
            setTimeout(() => {
                this.inputTextArea.focus()
                this.inputTextArea.selectionEnd = this.inputTextArea.value.length
            }, 1)
        }
        return false
    }

    private onBlurTextarea(resetValue?: boolean) {
        if (resetValue) {
            this.resetValue()
        }
        if (this.openTextArea && this.type === 'text') {
            this.openTextArea = false
        }
    }

    private onBlur(resetValue?: boolean) {
        if (resetValue) {
            this.resetValue()
        }
        const element = this.$refs.inputField as HTMLElement
        element.blur()
    }

    private resetValue() {
        this.value = this.originProducts[this.product.id][this.path]
    }

    private checkInput(inputEvent: KeyboardEvent) {
        if (this.type === 'number') {
            if (inputEvent.key === '-' && this.value > 0) {
                this.value = -this.value
            } else if (inputEvent.key === '+' && this.value < 0) {
                this.value = -this.value
            }
            const bannedCharacters = floatTables.includes(this.path.split('.')[0]) ? bannedFloatInput : bannedIntInput
            if (bannedCharacters.includes(inputEvent.key)) {
                inputEvent.preventDefault()
            }
        }
    }
}

export default TableInput
