













import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
    computed: {
        ...mapState('mpe', ['selectedCriteria'])
    }
})
class TableContextMenu extends Vue {
    @Prop({ type: String })
    readonly title!: string
    @Prop({ type: String, default: '' })
    readonly selectedItemColumn!: string
    // mapGetters
    readonly selectedCriteria!: TableCriteriaItem[]

    // data
    private items: TextValueItem<string | boolean>[] = []

    public mounted() {
        this.onHeaderChange(this.selectedItemColumn)
    }

    @Watch('selectedItemColumn')
    private onHeaderChange(val: any) {
        const currentHeader = this.selectedCriteria.find(h => h.id === val)
        if (currentHeader) {
            this.items = currentHeader.enum ? currentHeader.enum : []
        }
    }

    private onClick(enumVal: string) {
        this.$emit('context-menu-item-clicked', enumVal)
    }
}

export default TableContextMenu
