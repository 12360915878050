



































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('schema', ['marketSegments', 'technologies']),
        ...mapState('mpe', ['selectedSegment', 'selectedTechnology'])
    }
})
class ContextBarDropdownSegmentInformationControl extends Vue {
    // mapState
    readonly marketSegments!: TextValueItem<number>[]
    readonly technologies!: TextValueItem<number>[]
    readonly selectedSegment!: TextValueItem<number>
    readonly selectedTechnology!: TextValueItem<number>

    // Data
    currentSegment: Number | null = null
    currentTechnology: Number | null = null

    created() {
        this.currentSegment = this.selectedSegment.value
        this.currentTechnology = this.selectedTechnology.value
    }

    setSegmentAndTechnology() {
        this.$store.dispatch('mpe/applySegment', this.currentSegment)
        this.$store.dispatch('mpe/applyTechnology', this.currentTechnology)
    }
}
export default ContextBarDropdownSegmentInformationControl
