






























































import Vue from 'vue'
import { mdiMagnify } from '@mdi/js'
import Component from 'vue-class-component'

import ContextBarDropdownList from '@/components/multiple-products-editor/ContextBarDropdownList.vue'
import ContextBarDropdownListGroups from '@/components/multiple-products-editor/ContextBarDropdownListGroups.vue'
import ContextBarDropdownSegmentInformationControl from '@/components/multiple-products-editor/ContextBarDropdownSegmentInformationControl.vue'
import { Prop, Watch, Ref } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { extractAndDisplayError } from '@/utils'

@Component({
    components: {
        ContextBarDropdownSegmentInformationControl,
        ContextBarDropdownList,
        ContextBarDropdownListGroups
    },
    computed: {
        ...mapState('mpe', ['selectedCriteria'])
    }
})
class ContextBarDropdown extends Vue {
    @Prop({ type: String, default: '' })
    readonly label!: string
    @Prop({ type: Boolean, default: false })
    readonly hasGroups!: string
    @Prop({ type: Boolean, default: true })
    readonly searchBar!: string
    @Prop({ type: Boolean, default: false })
    readonly segmentInformationControls!: string
    @Prop({ type: Array as () => TableCriteriaItem[] | TableListItem[], required: true })
    readonly items!: TableCriteriaItem[] | TableListItem[]
    // mapState
    selectedCriteria!: TableCriteriaItem[]
    // data
    private currentSelection: TableCriteriaItem[] = []
    private isOpen: boolean = false
    private searchTerm: string = ''
    private svgMagnify: string = mdiMagnify
    // noty
    private $noty!: NotyObj

    // computed
    get isTextSearch() {
        return this.searchTerm.length > 0
    }

    public created() {
        if (this.selectedCriteria && this.selectedCriteria.length > 0) {
            this.currentSelection = [...this.selectedCriteria]
        }
    }

    private onSelectionChange(values: TableCriteriaItem[]) {
        this.currentSelection = [...values]
    }

    private async onClickApply() {
        if (this.segmentInformationControl) {
            this.segmentInformationControl.setSegmentAndTechnology()
        }
        this.isOpen = false
        if (this.currentSelection) {
            try {
                this.$store.commit('mpe/setLoading', true)
                await this.$store.dispatch('mpe/applySelectedCriteria', this.currentSelection)
            } catch (e) {
                this.$store.commit('mpe/setLoading', false)
                extractAndDisplayError(e, this.$noty)
                throw e
            }
        }
    }

    onClickCancel() {
        this.isOpen = false
    }

    @Watch('isOpen')
    private onClose(isOpen: Boolean) {
        if (!isOpen) {
            this.searchTerm = ''
            this.currentSelection = [...this.selectedCriteria]
        }
    }

    @Ref()
    readonly segmentInformationControl?: {
        setSegmentAndTechnology: () => void
    }
}

export default ContextBarDropdown
