





































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { mdiMenuDown } from '@mdi/js'

import TableInput from '@/components/multiple-products-editor/TableInput.vue'
import ScoreDisplay from './score/ScoreDisplay.vue'
import i18n from '@/i18n'

@Component({
    components: {
        TableInput,
        ScoreDisplay
    },
    computed: {
        ...mapState('mpe', ['originProducts']),
        ...mapGetters('auth', ['restrictedProductGroups'])
    }
})
class TableRow extends Vue {
    @Prop({ type: Number, required: true })
    readonly index!: number
    @Prop({ type: Array as () => TableCriteriaItem[], required: true })
    readonly selectedCriteria!: TableCriteriaItem[]
    @Prop({ type: Object as () => Product, required: true })
    readonly product!: Product
    @Prop({ type: String, required: true })
    readonly rangeSelectedColumn!: string
    @Prop({ type: Boolean, required: true })
    readonly isRangeSelectedRow!: boolean
    @Prop({ type: Boolean, required: true })
    readonly isDragged!: boolean
    @Prop({ type: Boolean, default: false })
    readonly isVirtualized!: boolean

    // MapState
    readonly originProducts!: KeyMap<Product>
    readonly restrictedProductGroups!: String[]

    // data
    readonly menuDownIcon = mdiMenuDown

    get isSelected() {
        return this.product.isSelected
    }
    set isSelected(value) {
        this.$store.commit('mpe/setDeselectedProduct', {
            index: this.index,
            id: this.product.id,
            value
        })
    }

    get originData() {
        return this.originProducts[this.product.id]
    }

    private canEdit(crit: TableCriteriaItem) {
        let inProductGroupScope = true
        if (crit.categoryId === 'global-technical-information') {
            inProductGroupScope =
                this.restrictedProductGroups.length === 0 ||
                this.restrictedProductGroups.includes(this.product.productGroupWebsite)
        }
        return crit.isEditable && crit.isInEditScope && inProductGroupScope
    }

    private isRangeSelected(headerValue: string) {
        return this.isRangeSelectedRow && headerValue === this.rangeSelectedColumn
    }

    private isDraggedCell(headerValue: string) {
        return this.isDragged && headerValue === this.rangeSelectedColumn
    }

    private hasChanged(crit: TableCriteriaItem) {
        if (crit.isProcessed) {
            return false
        }
        return this.product[crit.id] !== this.originData[crit.id]
    }

    private getLabel(crit: TableCriteriaItem) {
        const enumItem = crit?.enum?.find(e => e.value === this.product[crit.id])
        return enumItem
            ? enumItem.text
            : i18n.te(`product.values.${this.product[crit.id]}`)
            ? i18n.t(`product.values.${this.product[crit.id]}`)
            : this.product[crit.id]
    }

    private onColumnClick(columnName: string, columnType: string) {
        if (this.rangeSelectedColumn !== columnName) {
            this.$emit('onColumnClick', columnName, columnType)
        }
    }

    private onContextClick(e: MouseEvent, columnName: string) {
        this.$emit('onContextMenuClick', e, columnName)
    }

    private onClick(e: MouseEvent, crit: TableCriteriaItem) {
        if (e.button === 0 && crit.enum && crit.enum.length > 0) {
            this.$emit('triggerDropDown', e, this.product.id)
        }
    }
}

export default TableRow
