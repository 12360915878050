


















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import ContextBarDropdownList from '@/components/multiple-products-editor/ContextBarDropdownList.vue'

@Component({
    components: { ContextBarDropdownList }
})
class ContextBarDropdownListGroups extends Vue {
    @Prop({ type: Array as () => TableListItem[], required: true })
    readonly items!: TableListItem[]
    @Prop({ type: String, default: '' })
    readonly searchTerm!: String
    @Prop({ type: Array as () => TableCriteriaItem[], required: false })
    readonly selectedItems!: TableCriteriaItem[]

    private expanded: number[] = []
    private expandedSelected: number[] = []
    private selectedItemsState: TableCriteriaItem[] = []

    // computed
    get groupListIsEmpty() {
        const isEmptyList = this.items.map(group => this.groupIsEmpty(group.tableItems))
        return isEmptyList.every(b => b)
    }

    get upperItems() {
        return this.selectedItemsState.filter(selectedItem =>
            this.allTableItems.some(item => item.id === selectedItem.id)
        )
    }

    get allTableItems() {
        let allTableItems: TableCriteriaItem[] = []
        this.items
            .map(item => item.tableItems)
            .forEach(items => {
                allTableItems = allTableItems.concat(items)
            })
        return allTableItems
    }

    public created() {
        this.expanded = this.items.map((e: any, i: number) => i)
        this.expandedSelected = [0]
        if (this.selectedItems && this.selectedItems.length > 0) {
            this.selectedItemsState = [...this.selectedItems]
        }
    }

    private groupIsEmpty(items: TableCriteriaItem[]) {
        return this.filterSelectedItems(items).length === 0
    }

    private filterSelectedItems(items: TableCriteriaItem[]) {
        const unselectedItems = items.filter(item => !this.selectedItemsState.some(selItem => selItem.id === item.id))
        return unselectedItems.filter(e => e.text.toLowerCase().includes(this.searchTerm.toLowerCase()))
    }

    private onCriteriaListChange(val: any) {
        if (this.selectedItemsState.length === 0) {
            this.expandedSelected = [0]
        }
        this.selectedItemsState.push(val)
    }

    private onSelectedListChange(val: any) {
        this.selectedItemsState = this.selectedItemsState.filter(el => el !== val)
    }

    private onSelect(val: TableCriteriaItem, allVal: TableCriteriaItem[]) {
        this.$emit('on-select', val, allVal)
    }

    @Watch('selectedItemsState')
    private onSelectedItemsStateChange() {
        this.$emit('on-select-all', this.selectedItemsState)
    }

    private clearSelectedItemsGroups() {
        this.selectedItemsState = this.selectedItemsState.filter(
            selectedItem => !this.allTableItems.some(item => item.id === selectedItem.id)
        )
    }
}

export default ContextBarDropdownListGroups
