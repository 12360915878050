const dosageLevels = (value: number | string) => !value || value === 'UNSET' || (value > -1000000 && value < 1000000)

export default {
    description: (value: string) => !value || value.length <= 1000,
    name: (value: string) => value && value.length > 0,
    msdsId: (value: number) => value && value > 0 && Number.isInteger(value),
    'dosageLevels.carbonBlackMax': dosageLevels,
    'dosageLevels.carbonBlackMin': dosageLevels,
    'dosageLevels.inorganicPigmentMax': dosageLevels,
    'dosageLevels.inorganicPigmentMin': dosageLevels,
    'dosageLevels.mainBinderSolidMax': dosageLevels,
    'dosageLevels.mainBinderSolidMin': dosageLevels,
    'dosageLevels.organicPigmentMax': dosageLevels,
    'dosageLevels.organicPigmentMin': dosageLevels,
    'dosageLevels.totalFormulationMax': dosageLevels,
    'dosageLevels.totalFormulationMin': dosageLevels
} as KeyMap<() => boolean>
