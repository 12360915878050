















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
class ContextBarDropdownList extends Vue {
    @Prop({ type: Array as () => TableCriteriaItem[], required: true })
    readonly items!: TableCriteriaItem[]
    @Prop({ type: String, default: '' })
    readonly searchTerm!: String
    @Prop({ type: Array as () => TableCriteriaItem[], required: false })
    readonly selectedItems!: TableCriteriaItem[]

    private selectedItemsState: TableCriteriaItem[] = []

    // computed
    get isSearching() {
        return this.searchTerm.length > 0
    }

    get filteredItems() {
        return this.items.filter(e => e.text.toLowerCase().includes(this.searchTerm.toLowerCase()))
    }

    public created() {
        if (this.selectedItems && this.selectedItems.length > 0) {
            this.selectedItemsState = [...this.selectedItems]
        }
    }

    private onSelect(val: TableCriteriaItem, allVal: TableCriteriaItem[]) {
        this.$emit('on-select', val)
        this.$emit('on-select-all', allVal)
    }

    @Watch('selectedItems')
    private onSelectedItemsChange(selectedItems: TableCriteriaItem[]) {
        this.selectedItemsState = [...selectedItems]
    }
}

export default ContextBarDropdownList
