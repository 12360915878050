





























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapGetters } from 'vuex'
import { mdiPlus, mdiMinus } from '@mdi/js'
import { extractAndDisplayError, isNumberBetween } from '@/utils'

import TableContextMenu from '@/components/multiple-products-editor/TableContextMenu.vue'
import TableHeader from '@/components/multiple-products-editor/TableHeader.vue'
import TableRow from '@/components/multiple-products-editor/TableRow.vue'
import ConfirmSaveDialog from '@/components/multiple-products-editor/ConfirmSaveDialog.vue'
import validationRules from './TableInputValidation'

@Component({
    components: {
        TableContextMenu,
        TableHeader,
        TableRow,
        ConfirmSaveDialog
    },
    computed: {
        ...mapState('mpe', ['products', 'selectedCriteria', 'originProducts']),
        ...mapGetters('mpe', ['getFilteredProducts']),
        ...mapGetters('auth', ['restrictedProductGroups'])
    },
    methods: {
        isNumberBetween
    }
})
class Table extends Vue {
    // mapState
    readonly products!: Product[]
    readonly originProducts!: KeyMap<Product>
    readonly restrictedProductGroups!: String[]

    // mapGetters
    readonly selectedCriteria!: TableCriteriaItem[]
    readonly getFilteredProducts!: Product[]

    $noty!: NotyObj

    // data
    readonly plusIcon = mdiPlus
    readonly minusIcon = mdiMinus
    private isSelectedOpen = true
    // range selection
    private isDragging = false
    private draggedIds: number[] = []
    private selectedStartItem: number | null = null
    private selectedEndItem: number | null = null
    private rangeSelectedColumn = ''
    private rangeSelectedType = ''
    private rangeSelectedIds: number[] = []
    private singleSelectedId: number | null = null
    // dropdown
    private selectionDropdownTitle = ''
    private showSelectionDropdown = false
    private x = 0
    private y = 0
    private minWidth: Number | undefined = 0
    // virtual scroll
    private virtualStart = 0
    private virtualEnd = 50
    private virtualScroll: any | null = null
    private timeout: NodeJS.Timeout | null = null

    private scrollTarget!: HTMLElement
    private rowHeight = 32
    private virtualPadding = 10
    private visibleRows = 50

    // computed
    get numberOfSelected() {
        return this.getFilteredProducts.filter(p => p.isSelected).length
    }

    get selectedProducts() {
        return this.getFilteredProducts.filter(r => r.isSelected)
    }

    get hasUnselectedProducts(): boolean {
        return this.products.filter(r => !r.isSelected).length === 0
    }

    get isSelectedTableDirty() {
        return this.selectedProducts
            .filter(p => p.isSelected)
            .map(p => Object.entries(p).toString() !== Object.entries(this.originProducts[p.id]).toString())
            .includes(true)
    }

    get isTableValid() {
        return !this.selectedProducts
            .map(p => Object.keys(p).map(key => (validationRules[key] ? validationRules[key](p[key]) : true)))
            .flat()
            .includes(false)
    }

    get scrollOffset() {
        return this.isSelectedOpen ? 0 : this.numberOfSelected
    }

    // methods
    public created() {
        window.addEventListener('keypress', this.onKeyPress)
        this.applyVirtualScroller()
    }

    public beforeUpdate() {
        this.$store.commit('mpe/setRendering', true)
        this.applyVirtualScroller()
    }

    public updated() {
        this.$store.commit('mpe/setRendering', false)
    }

    private applyVirtualScroller() {
        this.scrollTarget = document.querySelector('.v-data-table__wrapper') as HTMLElement
        if (this.scrollTarget) {
            this.onScroll()
            this.scrollTarget.addEventListener('scroll', this.onScroll)
        }
    }

    private onMouseDown(e: MouseEvent, rowIndex: number, rowId: number, isMeta?: boolean) {
        if (e.button === 0) {
            if (!isMeta) {
                this.rangeSelectedIds = []
            }
            this.isDragging = true
            this.selectedStartItem = rowIndex
            this.selectedEndItem = rowIndex
            this.draggedIds = [rowId]
        }
    }

    private onMouseMove(e: MouseEvent, id: number) {
        if (this.isDragging && this.selectedStartItem !== null && this.selectedEndItem !== id) {
            if (this.rangeSelectedType === 'select') {
                e.preventDefault()
                const selection = document.getSelection()
                if (selection) {
                    selection.empty()
                }
            }
            const activeElement = document.activeElement as HTMLElement
            activeElement.blur()
            this.selectedEndItem = id
            const min = Math.min(this.selectedStartItem, this.selectedEndItem)
            const max = Math.max(this.selectedStartItem, this.selectedEndItem)
            this.draggedIds = this.selectedProducts.slice(min, max + 1).map(i => i.id)
        }
    }

    private onMouseUp(e: MouseEvent, isMeta?: boolean) {
        if (this.selectedStartItem !== this.selectedEndItem) {
            this.rangeSelectedIds = [...new Set(this.rangeSelectedIds.concat(this.draggedIds))]
        } else if (isMeta && this.draggedIds.length === 1) {
            const singleSelectionId = this.draggedIds[0]
            if (this.rangeSelectedIds.includes(singleSelectionId)) {
                this.rangeSelectedIds = this.rangeSelectedIds.filter(id => id !== singleSelectionId)
            } else {
                this.rangeSelectedIds.push(singleSelectionId)
            }
        }
        if (this.rangeSelectedIds.length === 1) {
            this.rangeSelectedIds = []
        }
        this.selectedStartItem = null
        this.selectedEndItem = null
        this.draggedIds = []
        this.isDragging = false
    }

    private onColumnClick(columnName: string, columnType: string) {
        this.showSelectionDropdown = false
        if (columnName && this.rangeSelectedColumn !== columnName) {
            this.rangeSelectedColumn = columnName
            this.rangeSelectedType = columnType
        }
    }

    private rangeSelectId(rowId: number) {
        if (this.rangeSelectedIds.includes(rowId)) {
            this.rangeSelectedIds = this.rangeSelectedIds.filter(el => el !== rowId)
        } else {
            this.rangeSelectedIds.push(rowId)
        }
    }

    private toggleSelectionDropdown(e: MouseEvent, id?: number) {
        e.preventDefault()
        this.showSelectionDropdown = false
        this.minWidth = 0
        if (!id) {
            this.selectionDropdownTitle = this.$t('multipleProductsEditor.contextMenu.applyToSelection') as string
            this.x = e.clientX
            this.y = e.clientY
        } else {
            const target = e.currentTarget as HTMLElement
            this.selectionDropdownTitle = ''
            this.singleSelectedId = id
            this.x = target.getBoundingClientRect().x
            this.y = target.getBoundingClientRect().y
            this.minWidth = target.getBoundingClientRect().width
        }
        if (id || this.rangeSelectedIds.length > 0) {
            setTimeout(
                () =>
                    this.$nextTick(() => {
                        this.showSelectionDropdown = true
                    }),
                0
            )
        }
    }

    private isRendered(index: number, isSelected: boolean) {
        return isSelected
            ? index >= this.virtualStart && index <= this.virtualEnd
            : index + this.numberOfSelected >= this.virtualStart && index + this.numberOfSelected <= this.virtualEnd
    }

    private onCancel() {
        this.getFilteredProducts.forEach(product => {
            const originProduct = Object.assign({}, this.originProducts[product.id])
            if (Object.entries(originProduct).toString() !== Object.entries(product).toString()) {
                this.$store.commit('mpe/setProduct', { id: product.id, product: originProduct })
            }
        })
    }

    private async onSave() {
        const editedProducts = this.getFilteredProducts
            .filter(product => {
                const originProduct = Object.assign({}, this.originProducts[product.id])
                return (
                    product.isSelected &&
                    Object.entries(originProduct).toString() !== Object.entries(product).toString()
                )
            })
            .map(p => p.id)

        try {
            const response = await this.$store.dispatch('mpe/saveProducts', editedProducts)
            this.$noty.success(
                this.$t('multipleProductsEditor.graphQl.updateSuccessMessage', [response.updateProducts]) as string,
                {
                    timeout: 2000
                }
            )
        } catch (e) {
            this.$store.commit('mpe/setLoading', false)
            extractAndDisplayError(e, this.$noty)
            throw e
        }
    }

    private onBlur() {
        this.selectedStartItem = null
        this.selectedEndItem = null
        this.rangeSelectedIds = []
    }

    private onKeyPress(e: KeyboardEvent) {
        if (this.rangeSelectedIds.length > 0) {
            const number = parseInt(e.key, 10)
            const enums = this.selectedCriteria.find(h => h.id === this.rangeSelectedColumn)?.enum
            let searchFor = (en: TextValueItem<string | boolean>) => !en

            if (!Number.isNaN(number)) {
                searchFor = (en: TextValueItem<string | boolean>) => parseInt(en?.text, 10) === number
            } else {
                if (e.code === 'KeyN') {
                    searchFor = (en: TextValueItem<string | boolean>) => en?.text === 'NOT_APPLICABLE'
                }
                if (e.code === 'KeyU') {
                    searchFor = (en: TextValueItem<string | boolean>) => en?.text === 'UNSET'
                }
            }
            const value = enums?.find(searchFor)?.value
            if (value) {
                this.selectValueForRange(value)
            }
        }
    }

    public setColumnTo(columnName: string, value: string | boolean) {
        this.selectedProducts.forEach(product => {
            const columnHeader = this.selectedCriteria.find(crit => crit.id === columnName)
            if (columnHeader) {
                // check for global technical product group specific rights
                if (
                    columnHeader.categoryId === 'global-technical-information' &&
                    this.restrictedProductGroups.length > 0 &&
                    !this.restrictedProductGroups.includes(product.productGroupWebsite)
                ) {
                    return
                }

                this.$store.commit('mpe/setProductValue', {
                    id: product.id,
                    path: columnHeader.id,
                    value
                })
                this.$store.dispatch('mpe/updateScoreForProduct', { id: product.id })
            }
        })
    }

    private selectValueForRange(value: string | boolean) {
        const range = this.rangeSelectedIds.length > 0 ? this.rangeSelectedIds : [this.singleSelectedId]
        range.forEach(id => {
            const columnHeader = this.selectedCriteria.find(crit => crit.id === this.rangeSelectedColumn)
            if (columnHeader) {
                this.$store.commit('mpe/setProductValue', {
                    path: columnHeader.id,
                    id,
                    value
                })
                this.$store.dispatch('mpe/updateScoreForProduct', { id })
            }
        })
    }

    private toggleSelectionGroup(isSelected: boolean, isOpen: boolean, toggle: () => void) {
        if (isSelected) {
            this.isSelectedOpen = !isOpen
            if (isOpen) {
                this.virtualStart += this.numberOfSelected
                this.virtualEnd += this.numberOfSelected
            } else {
                this.virtualStart -= this.numberOfSelected
                this.virtualEnd -= this.numberOfSelected
            }
        }
        toggle()
        this.onScroll()
    }

    private onScroll() {
        // debounce if scrolling fast
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
            const { scrollTop, offsetHeight } = this.scrollTarget
            this.visibleRows = Math.ceil(offsetHeight / this.rowHeight) + this.virtualPadding * 2
            this.virtualStart = Math.ceil(scrollTop / this.rowHeight) - this.virtualPadding + this.scrollOffset
            this.virtualEnd = this.virtualStart + this.visibleRows + this.scrollOffset
        }, 20)
    }

    translateValue = (crit: TableCriteriaItem, item: Product) => {
        const itemValue = item[crit.id]
        return crit?.enum?.find(e => e.value === itemValue)?.text
    }
}

export default Table
