









import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import scoreUtils from './scoreCalculationUtils'

@Component({
    computed: {
        ...mapState('mpe', ['originProducts']),
        ...mapGetters('mpe', ['regulatoryCriteria'])
    }
})
class ScoreDisplay extends Vue {
    @Prop({ type: String, required: true })
    readonly type!: string

    @Prop({ type: Object as () => Product, required: true })
    readonly product!: Product

    readonly threshold = 0.7
    // MapState
    readonly originProducts!: KeyMap<Product>
    readonly regulatoryCriteria!: TableCriteriaItem[]

    get formattedScore() {
        if (this.product[this.type]) {
            return this.product[this.type].toFixed(2)
        } else {
            return (0).toFixed(2)
        }
    }

    get hasChanged() {
        return this.product[this.type] !== this.originProducts[this.product.id][this.type]
    }

    get isRecommended() {
        return (
            this.product[this.type] >= this.threshold &&
            scoreUtils.isRegulatoryCompliant(this.product, this.regulatoryCriteria)
        )
    }
}

export default ScoreDisplay
