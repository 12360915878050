import { render, staticRenderFns } from "./SegmentOverview.vue?vue&type=template&id=718b603c&scoped=true&"
import script from "./SegmentOverview.vue?vue&type=script&lang=ts&"
export * from "./SegmentOverview.vue?vue&type=script&lang=ts&"
import style0 from "./SegmentOverview.vue?vue&type=style&index=0&id=718b603c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718b603c",
  null
  
)

export default component.exports