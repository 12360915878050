













import ContextBar from '@/components/multiple-products-editor/ContextBar.vue'
import MpeTable from '@/components/multiple-products-editor/Table.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { mapState } from 'vuex'

export default {
    name: 'MultipleProductsEditor',
    components: {
        ContextBar,
        MpeTable,
        LoadingSpinner
    },
    computed: {
        ...mapState('mpe', ['isLoading', 'isRendering'])
    }
}
