







import Component from 'vue-class-component'
import Vue from 'vue'
import { mapState } from 'vuex'
import { Prop } from 'vue-property-decorator'

@Component({
    computed: {
        ...mapState('mpe', ['selectedSegment', 'selectedTechnology'])
    }
})
class ContextBar extends Vue {
    @Prop({ type: Boolean, default: true })
    readonly showTechnology!: boolean

    // mapGetters
    readonly selectedSegment!: TextValueItem<number>
    readonly selectedTechnology!: TextValueItem<number>
}

export default ContextBar
