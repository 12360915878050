























import ContextBarDropdown from '@/components/multiple-products-editor/ContextBarDropdown.vue'

import Component from 'vue-class-component'
import Vue from 'vue'
import { mapState } from 'vuex'

import categoryConfig, { preSelection } from '@/components/multiple-products-editor/contextBarConfig'
import { contextBarConfig } from '@/components/multiple-products-editor/score/scoreInformationConfig'
import { extractAndDisplayError } from '@/utils'

@Component({
    components: { ContextBarDropdown },
    computed: {
        ...mapState('schema', ['tableSchemasLoaded', 'tableItemsByCategory'])
    }
})
class ContextBar extends Vue {
    // configuration
    readonly categoryConfig = categoryConfig
    readonly scoreItems = contextBarConfig

    // mapState
    readonly tableSchemasLoaded!: boolean
    readonly tableItemsByCategory!: { [key: string]: TableCriteriaItem[] }
    // noty
    private $noty!: NotyObj

    private async created() {
        try {
            this.$store.commit('mpe/setLoading', true)

            await this.$store.dispatch('schema/init', this.categoryConfig.map(category => category.tableNames).flat())

            const initialSelection = this.categoryConfig
                .map(category => this.tableItemsByCategory[category.id])
                .flat()
                .map((list: any) => (list.tableItems ? list.tableItems : list))
                .flat()
                .filter(item => preSelection.includes(item.id))
            const orderedSelection = preSelection.map(id => initialSelection.find(item => item.id === id))
            await this.$store.dispatch('mpe/initMpe', orderedSelection)
        } catch (e) {
            this.$store.commit('mpe/setLoading', false)
            extractAndDisplayError(e, this.$noty)
            throw e
        }
    }
}

export default ContextBar
