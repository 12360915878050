

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
class ConfirmSaveDialog extends Vue {
    @Prop({ type: Boolean, required: true })
    readonly isDisabled!: () => void
    @Prop({ type: Function, required: true })
    readonly confirmCallback!: () => void

    private isOpen = false

    private onConfirmButton() {
        this.isOpen = false
        this.confirmCallback()
    }
}

export default ConfirmSaveDialog
