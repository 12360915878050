




































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'

import { mdiClose, mdiFilterVariant, mdiMagnify, mdiPlusThick } from '@mdi/js'

@Component({
    computed: {
        ...mapState('mpe', ['appliedFilters'])
    }
})
class TableFilterModal extends Vue {
    @Prop({ type: Object as () => TableCriteriaItem, required: true })
    readonly criterion!: TableCriteriaItem

    // mapGetters
    readonly appliedFilters!: { [key: string]: TableFilter }

    // data
    readonly closeIcon: string = mdiClose
    readonly filterIcon: string = mdiFilterVariant
    readonly magnifyIcon: string = mdiMagnify
    readonly plusIcon: string = mdiPlusThick
    private isOpen: boolean = false
    private type: string = this.isTextFilter ? 'text' : 'options'
    private searchTerm: string = ''

    get values() {
        if (this.appliedFilters[this.criterion.id]) {
            return this.appliedFilters[this.criterion.id].values
        } else {
            return []
        }
    }

    set values(val) {
        this.commitChange(val)
    }

    // computed
    get items() {
        if (this.criterion.criteriaName === 'segmentation' && this.criterion.enum) {
            return this.criterion.enum.filter((e: TextValueItem<string | boolean>) => e.value !== 'CANCEL')
        }
        return this.criterion.enum ? this.criterion.enum : [null, true, false].map(value => ({ value }))
    }

    get isTextFilter() {
        return !(this.criterion.enum && this.criterion.enum.length > 0) && this.criterion.type !== 'boolean'
    }

    get headerLabel() {
        return this.$t('multipleProductsEditor.filter.headerLabel', [this.criterion.text])
    }

    get hasActiveFilter() {
        const filterKeys = Object.keys(this.appliedFilters)
        return filterKeys.includes(this.criterion.id) || this.appliedFilters[this.criterion.id]?.values?.length === 0
    }

    private commitChange(values: unknown[]) {
        this.$store.commit('mpe/setFilter', {
            key: this.criterion.id,
            type: this.type,
            values: [...values]
        })
    }

    private onAdd() {
        if (this.searchTerm.length > 0) {
            const newValues = [...this.values]
            newValues.push(this.searchTerm)
            this.commitChange(newValues)
            this.searchTerm = ''
        }
    }

    private onRemove(index: number) {
        this.commitChange(this.values.filter((_: unknown, i: number) => i !== index))
    }

    private onClear() {
        this.commitChange([])
    }

    private onClose() {
        this.searchTerm = ''
        this.isOpen = false
    }
}

export default TableFilterModal
